button:
  about: حول
  back: رجوع
  home: الرئيسية
  toggle_dark: التغيير إلى الوضع المظلم
  toggle_langs: تغيير اللغة
create:
  media:
    project: إنشاء مشروع إعلامي
errors:
  business: |-
    كانت هناك أخطاء. 
    يرجى التحقق من تفاصيل الخطأ أدناه.


    {errors}
general: {}
intro:
  hi: مرحبا {name}
not-found: صفحة غير موجودة
receiver: متلقي
