button:
  about: Về
  back: Quay lại
  home: Khởi đầu
  toggle_dark: Chuyển đổi chế độ tối
  toggle_langs: Thay đổi ngôn ngữ
create:
  media:
    project: Tạo dự án truyền thông
errors:
  business: |-
    Có lỗi. 
    Vui lòng kiểm tra chi tiết lỗi bên dưới.


    {errors}
general: {}
intro:
  hi: Hi, {name}!
not-found: Không tìm thấy
receiver: Người nhận
