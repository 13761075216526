/* tslint:disable */
/* eslint-disable */
/**
 * imma API
 * imma is a platform to manage emotional messages for your beloved ones and send those on trigger events
 *
 * The version of the OpenAPI document: v0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { MediaProjectType } from '../model';
// @ts-ignore
import { RequestCallbackDto } from '../model';
// @ts-ignore
import { ResultGetAuthorResult } from '../model';
// @ts-ignore
import { ResultGetStorageSubscriptionInfoForUploadResponse } from '../model';
// @ts-ignore
import { ResultUnit } from '../model';
// @ts-ignore
import { SetDateOfBirthDto } from '../model';
// @ts-ignore
import { SetLanguageDto } from '../model';
/**
 * AuthorControllerApi - axios parameter creator
 * @export
 */
export const AuthorControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get1: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('get1', 'id', id)
            const localVarPath = `/author/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {MediaProjectType} mediaProjectType 
         * @param {number} expectedFileSizeInBytes 
         * @param {boolean} isPublishMode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStorageInfoForUpload: async (id: string, mediaProjectType: MediaProjectType, expectedFileSizeInBytes: number, isPublishMode: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getStorageInfoForUpload', 'id', id)
            // verify required parameter 'mediaProjectType' is not null or undefined
            assertParamExists('getStorageInfoForUpload', 'mediaProjectType', mediaProjectType)
            // verify required parameter 'expectedFileSizeInBytes' is not null or undefined
            assertParamExists('getStorageInfoForUpload', 'expectedFileSizeInBytes', expectedFileSizeInBytes)
            // verify required parameter 'isPublishMode' is not null or undefined
            assertParamExists('getStorageInfoForUpload', 'isPublishMode', isPublishMode)
            const localVarPath = `/author/{id}/storage-info-for-upload`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (mediaProjectType !== undefined) {
                localVarQueryParameter['mediaProjectType'] = mediaProjectType;
            }

            if (expectedFileSizeInBytes !== undefined) {
                localVarQueryParameter['expectedFileSizeInBytes'] = expectedFileSizeInBytes;
            }

            if (isPublishMode !== undefined) {
                localVarQueryParameter['isPublishMode'] = isPublishMode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {RequestCallbackDto} requestCallbackDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestCallback: async (id: string, requestCallbackDto: RequestCallbackDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('requestCallback', 'id', id)
            // verify required parameter 'requestCallbackDto' is not null or undefined
            assertParamExists('requestCallback', 'requestCallbackDto', requestCallbackDto)
            const localVarPath = `/author/{id}/callback-request`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestCallbackDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {SetDateOfBirthDto} setDateOfBirthDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDateOfBirth: async (id: string, setDateOfBirthDto: SetDateOfBirthDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateDateOfBirth', 'id', id)
            // verify required parameter 'setDateOfBirthDto' is not null or undefined
            assertParamExists('updateDateOfBirth', 'setDateOfBirthDto', setDateOfBirthDto)
            const localVarPath = `/author/{id}/date-of-birth`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setDateOfBirthDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {SetLanguageDto} setLanguageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLanguage: async (id: string, setLanguageDto: SetLanguageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateLanguage', 'id', id)
            // verify required parameter 'setLanguageDto' is not null or undefined
            assertParamExists('updateLanguage', 'setLanguageDto', setLanguageDto)
            const localVarPath = `/author/{id}/language`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setLanguageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {any} document 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadSignedConsent: async (id: string, document: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('uploadSignedConsent', 'id', id)
            // verify required parameter 'document' is not null or undefined
            assertParamExists('uploadSignedConsent', 'document', document)
            const localVarPath = `/author/{id}/signed-consent`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (document !== undefined) { 
                localVarFormParams.append('document', document as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthorControllerApi - functional programming interface
 * @export
 */
export const AuthorControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthorControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get1(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultGetAuthorResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {MediaProjectType} mediaProjectType 
         * @param {number} expectedFileSizeInBytes 
         * @param {boolean} isPublishMode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStorageInfoForUpload(id: string, mediaProjectType: MediaProjectType, expectedFileSizeInBytes: number, isPublishMode: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultGetStorageSubscriptionInfoForUploadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStorageInfoForUpload(id, mediaProjectType, expectedFileSizeInBytes, isPublishMode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {RequestCallbackDto} requestCallbackDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestCallback(id: string, requestCallbackDto: RequestCallbackDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultUnit>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestCallback(id, requestCallbackDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {SetDateOfBirthDto} setDateOfBirthDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDateOfBirth(id: string, setDateOfBirthDto: SetDateOfBirthDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultUnit>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDateOfBirth(id, setDateOfBirthDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {SetLanguageDto} setLanguageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLanguage(id: string, setLanguageDto: SetLanguageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultUnit>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLanguage(id, setLanguageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {any} document 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadSignedConsent(id: string, document: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultUnit>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadSignedConsent(id, document, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthorControllerApi - factory interface
 * @export
 */
export const AuthorControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthorControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get1(id: string, options?: any): AxiosPromise<ResultGetAuthorResult> {
            return localVarFp.get1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {MediaProjectType} mediaProjectType 
         * @param {number} expectedFileSizeInBytes 
         * @param {boolean} isPublishMode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStorageInfoForUpload(id: string, mediaProjectType: MediaProjectType, expectedFileSizeInBytes: number, isPublishMode: boolean, options?: any): AxiosPromise<ResultGetStorageSubscriptionInfoForUploadResponse> {
            return localVarFp.getStorageInfoForUpload(id, mediaProjectType, expectedFileSizeInBytes, isPublishMode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {RequestCallbackDto} requestCallbackDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestCallback(id: string, requestCallbackDto: RequestCallbackDto, options?: any): AxiosPromise<ResultUnit> {
            return localVarFp.requestCallback(id, requestCallbackDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {SetDateOfBirthDto} setDateOfBirthDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDateOfBirth(id: string, setDateOfBirthDto: SetDateOfBirthDto, options?: any): AxiosPromise<ResultUnit> {
            return localVarFp.updateDateOfBirth(id, setDateOfBirthDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {SetLanguageDto} setLanguageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLanguage(id: string, setLanguageDto: SetLanguageDto, options?: any): AxiosPromise<ResultUnit> {
            return localVarFp.updateLanguage(id, setLanguageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {any} document 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadSignedConsent(id: string, document: any, options?: any): AxiosPromise<ResultUnit> {
            return localVarFp.uploadSignedConsent(id, document, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthorControllerApi - object-oriented interface
 * @export
 * @class AuthorControllerApi
 * @extends {BaseAPI}
 */
export class AuthorControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorControllerApi
     */
    public get1(id: string, options?: AxiosRequestConfig) {
        return AuthorControllerApiFp(this.configuration).get1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {MediaProjectType} mediaProjectType 
     * @param {number} expectedFileSizeInBytes 
     * @param {boolean} isPublishMode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorControllerApi
     */
    public getStorageInfoForUpload(id: string, mediaProjectType: MediaProjectType, expectedFileSizeInBytes: number, isPublishMode: boolean, options?: AxiosRequestConfig) {
        return AuthorControllerApiFp(this.configuration).getStorageInfoForUpload(id, mediaProjectType, expectedFileSizeInBytes, isPublishMode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {RequestCallbackDto} requestCallbackDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorControllerApi
     */
    public requestCallback(id: string, requestCallbackDto: RequestCallbackDto, options?: AxiosRequestConfig) {
        return AuthorControllerApiFp(this.configuration).requestCallback(id, requestCallbackDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {SetDateOfBirthDto} setDateOfBirthDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorControllerApi
     */
    public updateDateOfBirth(id: string, setDateOfBirthDto: SetDateOfBirthDto, options?: AxiosRequestConfig) {
        return AuthorControllerApiFp(this.configuration).updateDateOfBirth(id, setDateOfBirthDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {SetLanguageDto} setLanguageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorControllerApi
     */
    public updateLanguage(id: string, setLanguageDto: SetLanguageDto, options?: AxiosRequestConfig) {
        return AuthorControllerApiFp(this.configuration).updateLanguage(id, setLanguageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {any} document 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorControllerApi
     */
    public uploadSignedConsent(id: string, document: any, options?: AxiosRequestConfig) {
        return AuthorControllerApiFp(this.configuration).uploadSignedConsent(id, document, options).then((request) => request(this.axios, this.basePath));
    }
}
