<script setup lang="ts">
import type { MediaProjectType } from '~/api/gen'

const props = defineProps<{
  data: {
    id: string
    type: MediaProjectType
    categoryName: string
    name: string
  }
}>()

const router = useRouter()

const continueProject = () => {
  router.push(`/media-project-draft/${props.data.id}`)
}
const { t } = useI18n()

const state = reactive({
  svg: { backgroundImage: '' },
  inProgress: false,

})

// dynamic  import Logo from `~/svg/${props.categoryName}.svg?url`
import(`../../svg/${props.data.categoryName}.jpg`)
  // .then(e => state.svg = { backgroundImage: `url(${e.default})` })
  .then(e => state.svg = { backgroundImage: `url(${e.default})` })
</script>

<template>
  <div class="p-1rem hover:cursor-pointer group custom-shadow max-w-10rem" @click="continueProject()">
    <div class="w-8rem h-8rem flex bg-gray-200 border-gray group-hover:border-gray-5 border-1 rounded-50%" :style="state.svg" />

    <div class="text-center mt-4 font-bold text-primary text-ellipsis overflow-hidden truncate">
      {{ t(`video-categories.${props.data.name}`, props.data.name) }}
    </div>
  </div>
</template>

<style scoped lang="postcss">
.custom-shadow:hover {
  box-shadow: 0 0 10px rgb(0 0 0 / 0.1), 0px 0px 10px rgb(0 0 0 / 0.1);
}

.dark .custom-shadow:hover {
  box-shadow: 0 0 10px rgb(255 255 255 / 0.9), 0px 0px 10px rgb(255 255 255 / 0.9);
}
</style>

