/* tslint:disable */
/* eslint-disable */
/**
 * imma API
 * imma is a platform to manage emotional messages for your beloved ones and send those on trigger events
 *
 * The version of the OpenAPI document: v0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const AuthorStatus = {
    PassedAway: 'PassedAway',
    AutomatedProcessesSetupDone: 'AutomatedProcessesSetupDone',
    InitializingAutomatedProcesses: 'InitializingAutomatedProcesses',
    Created: 'Created',
    SignatureValidation: 'SignatureValidation'
} as const;

export type AuthorStatus = typeof AuthorStatus[keyof typeof AuthorStatus];



