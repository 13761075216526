button:
  about: Tentang
  back: Kembali
  home: Beranda
  toggle_dark: Ubah ke mode gelap
  toggle_langs: Ubah bahasa
create:
  media:
    project: Buat Proyek Media
errors:
  business: |-
    Ada kesalahan. 
    Silakan periksa detail kesalahan di bawah ini.


    {errors}
general: {}
intro:
  hi: Halo, {name}!
not-found: Tidak ditemukan
receiver: Penerima
