import { createI18n } from 'vue-i18n'
import { type UserModule } from '~/types'

const getCurrentLanguage = (): Language =>
  localStorage.getItem('language')
    ?? (navigator.language && navigator.language.indexOf('-')
      ? navigator.language.split('-')[0]
      : 'de')

// Import i18n resources
// https://vitejs.dev/guide/features.html#glob-import
const messages = Object.fromEntries(
  Object.entries(import.meta.globEager('../../locales/*.y(a)?ml'))
    .map(([key, value]) => {
      const yaml = key.endsWith('.yaml')
      return [key.slice(14, yaml ? -5 : -4), value.default]
    }),
)

const i18n = createI18n({
  legacy: false,
  locale: getCurrentLanguage(),
  messages,
})

const install: UserModule = ({ app }) => {
  app.use(i18n)
}

export type Language = string
export { install, i18n, getCurrentLanguage }
