/* tslint:disable */
/* eslint-disable */
/**
 * imma API
 * imma is a platform to manage emotional messages for your beloved ones and send those on trigger events
 *
 * The version of the OpenAPI document: v0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateMediaProjectDraftDto } from '../model';
// @ts-ignore
import { RenameDraft } from '../model';
// @ts-ignore
import { ResultGetLatestProjectsDraftResponse } from '../model';
// @ts-ignore
import { ResultListMediaProjectDraftResult } from '../model';
// @ts-ignore
import { ResultMediaProjectDraftDto } from '../model';
// @ts-ignore
import { ResultMediaProjectDraftId } from '../model';
// @ts-ignore
import { ResultUnit } from '../model';
// @ts-ignore
import { SaveAssetsInformationDto } from '../model';
// @ts-ignore
import { SaveDateTrigger } from '../model';
// @ts-ignore
import { SaveRecipientDraft } from '../model';
/**
 * MediaProjectDraftControllerApi - axios parameter creator
 * @export
 */
export const MediaProjectDraftControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateMediaProjectDraftDto} createMediaProjectDraftDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDraft: async (createMediaProjectDraftDto: CreateMediaProjectDraftDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createMediaProjectDraftDto' is not null or undefined
            assertParamExists('createDraft', 'createMediaProjectDraftDto', createMediaProjectDraftDto)
            const localVarPath = `/media-project-drafts/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMediaProjectDraftDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDrafts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/media-project-drafts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDraft: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDraft', 'id', id)
            const localVarPath = `/media-project-drafts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestProjectDrafts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/media-project-drafts/latest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {RenameDraft} renameDraft 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renameDraft: async (id: string, renameDraft: RenameDraft, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('renameDraft', 'id', id)
            // verify required parameter 'renameDraft' is not null or undefined
            assertParamExists('renameDraft', 'renameDraft', renameDraft)
            const localVarPath = `/media-project-drafts/{id}/name`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(renameDraft, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {SaveAssetsInformationDto} saveAssetsInformationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAssetsInformationDraft: async (id: string, saveAssetsInformationDto: SaveAssetsInformationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('saveAssetsInformationDraft', 'id', id)
            // verify required parameter 'saveAssetsInformationDto' is not null or undefined
            assertParamExists('saveAssetsInformationDraft', 'saveAssetsInformationDto', saveAssetsInformationDto)
            const localVarPath = `/media-project-drafts/{id}/assets-information`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveAssetsInformationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {SaveRecipientDraft} saveRecipientDraft 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveRecipientDraft: async (id: string, saveRecipientDraft: SaveRecipientDraft, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('saveRecipientDraft', 'id', id)
            // verify required parameter 'saveRecipientDraft' is not null or undefined
            assertParamExists('saveRecipientDraft', 'saveRecipientDraft', saveRecipientDraft)
            const localVarPath = `/media-project-drafts/{id}/receiver-draft`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveRecipientDraft, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {SaveDateTrigger} saveDateTrigger 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveTrigger: async (id: string, saveDateTrigger: SaveDateTrigger, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('saveTrigger', 'id', id)
            // verify required parameter 'saveDateTrigger' is not null or undefined
            assertParamExists('saveTrigger', 'saveDateTrigger', saveDateTrigger)
            const localVarPath = `/media-project-drafts/{id}/trigger`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveDateTrigger, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MediaProjectDraftControllerApi - functional programming interface
 * @export
 */
export const MediaProjectDraftControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MediaProjectDraftControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateMediaProjectDraftDto} createMediaProjectDraftDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDraft(createMediaProjectDraftDto: CreateMediaProjectDraftDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultMediaProjectDraftId>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDraft(createMediaProjectDraftDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllDrafts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultListMediaProjectDraftResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllDrafts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDraft(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultMediaProjectDraftDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDraft(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLatestProjectDrafts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultGetLatestProjectsDraftResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLatestProjectDrafts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {RenameDraft} renameDraft 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async renameDraft(id: string, renameDraft: RenameDraft, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultUnit>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.renameDraft(id, renameDraft, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {SaveAssetsInformationDto} saveAssetsInformationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveAssetsInformationDraft(id: string, saveAssetsInformationDto: SaveAssetsInformationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultUnit>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveAssetsInformationDraft(id, saveAssetsInformationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {SaveRecipientDraft} saveRecipientDraft 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveRecipientDraft(id: string, saveRecipientDraft: SaveRecipientDraft, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultUnit>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveRecipientDraft(id, saveRecipientDraft, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {SaveDateTrigger} saveDateTrigger 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveTrigger(id: string, saveDateTrigger: SaveDateTrigger, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultUnit>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveTrigger(id, saveDateTrigger, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MediaProjectDraftControllerApi - factory interface
 * @export
 */
export const MediaProjectDraftControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MediaProjectDraftControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateMediaProjectDraftDto} createMediaProjectDraftDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDraft(createMediaProjectDraftDto: CreateMediaProjectDraftDto, options?: any): AxiosPromise<ResultMediaProjectDraftId> {
            return localVarFp.createDraft(createMediaProjectDraftDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDrafts(options?: any): AxiosPromise<ResultListMediaProjectDraftResult> {
            return localVarFp.getAllDrafts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDraft(id: string, options?: any): AxiosPromise<ResultMediaProjectDraftDto> {
            return localVarFp.getDraft(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestProjectDrafts(options?: any): AxiosPromise<ResultGetLatestProjectsDraftResponse> {
            return localVarFp.getLatestProjectDrafts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {RenameDraft} renameDraft 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renameDraft(id: string, renameDraft: RenameDraft, options?: any): AxiosPromise<ResultUnit> {
            return localVarFp.renameDraft(id, renameDraft, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {SaveAssetsInformationDto} saveAssetsInformationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAssetsInformationDraft(id: string, saveAssetsInformationDto: SaveAssetsInformationDto, options?: any): AxiosPromise<ResultUnit> {
            return localVarFp.saveAssetsInformationDraft(id, saveAssetsInformationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {SaveRecipientDraft} saveRecipientDraft 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveRecipientDraft(id: string, saveRecipientDraft: SaveRecipientDraft, options?: any): AxiosPromise<ResultUnit> {
            return localVarFp.saveRecipientDraft(id, saveRecipientDraft, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {SaveDateTrigger} saveDateTrigger 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveTrigger(id: string, saveDateTrigger: SaveDateTrigger, options?: any): AxiosPromise<ResultUnit> {
            return localVarFp.saveTrigger(id, saveDateTrigger, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MediaProjectDraftControllerApi - object-oriented interface
 * @export
 * @class MediaProjectDraftControllerApi
 * @extends {BaseAPI}
 */
export class MediaProjectDraftControllerApi extends BaseAPI {
    /**
     * 
     * @param {CreateMediaProjectDraftDto} createMediaProjectDraftDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaProjectDraftControllerApi
     */
    public createDraft(createMediaProjectDraftDto: CreateMediaProjectDraftDto, options?: AxiosRequestConfig) {
        return MediaProjectDraftControllerApiFp(this.configuration).createDraft(createMediaProjectDraftDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaProjectDraftControllerApi
     */
    public getAllDrafts(options?: AxiosRequestConfig) {
        return MediaProjectDraftControllerApiFp(this.configuration).getAllDrafts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaProjectDraftControllerApi
     */
    public getDraft(id: string, options?: AxiosRequestConfig) {
        return MediaProjectDraftControllerApiFp(this.configuration).getDraft(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaProjectDraftControllerApi
     */
    public getLatestProjectDrafts(options?: AxiosRequestConfig) {
        return MediaProjectDraftControllerApiFp(this.configuration).getLatestProjectDrafts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {RenameDraft} renameDraft 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaProjectDraftControllerApi
     */
    public renameDraft(id: string, renameDraft: RenameDraft, options?: AxiosRequestConfig) {
        return MediaProjectDraftControllerApiFp(this.configuration).renameDraft(id, renameDraft, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {SaveAssetsInformationDto} saveAssetsInformationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaProjectDraftControllerApi
     */
    public saveAssetsInformationDraft(id: string, saveAssetsInformationDto: SaveAssetsInformationDto, options?: AxiosRequestConfig) {
        return MediaProjectDraftControllerApiFp(this.configuration).saveAssetsInformationDraft(id, saveAssetsInformationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {SaveRecipientDraft} saveRecipientDraft 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaProjectDraftControllerApi
     */
    public saveRecipientDraft(id: string, saveRecipientDraft: SaveRecipientDraft, options?: AxiosRequestConfig) {
        return MediaProjectDraftControllerApiFp(this.configuration).saveRecipientDraft(id, saveRecipientDraft, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {SaveDateTrigger} saveDateTrigger 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaProjectDraftControllerApi
     */
    public saveTrigger(id: string, saveDateTrigger: SaveDateTrigger, options?: AxiosRequestConfig) {
        return MediaProjectDraftControllerApiFp(this.configuration).saveTrigger(id, saveDateTrigger, options).then((request) => request(this.axios, this.basePath));
    }
}
