button:
  about: О шаблоне
  back: Назад
  home: Главная
  toggle_dark: Включить темный режим
  toggle_langs: Сменить язык
create:
  media:
    project: Создать медиапроект
errors:
  business: |-
    Были ошибки. 
    Пожалуйста, проверьте подробности ошибки ниже.


    {errors}
general: {}
intro:
  hi: Привет, {name}!
not-found: Не найден
receiver: Получатель
