button:
  about: Su di me
  back: Indietro
  home: Home
  toggle_dark: Attiva/disattiva modalità scura
  toggle_langs: Cambia lingua
create:
  media:
    project: Crea progetto multimediale
errors:
  business: |-
    Ci sono stati degli errori. 
    Si prega di controllare i dettagli dell'errore di seguito.


    {errors}
general: {}
intro:
  hi: Ciao, {name}!
not-found: Non trovato
receiver: Destinatario
