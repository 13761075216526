button:
  about: O nas
  back: Wróć
  home: Strona główna
  toggle_dark: Ustaw tryb nocny
  toggle_langs: Zmień język
create:
  media:
    project: Utwórz projekt medialny
errors:
  business: |-
    Wystąpiły błędy. 
    Sprawdź szczegóły błędu poniżej.


    {errors}
general: {}
intro:
  hi: Cześć, {name}!
not-found: Nie znaleziono
receiver: Odbiorca
