button:
  about: Hakkımda
  back: Geri
  home: Anasayfa
  toggle_dark: Karanlık modu değiştir
  toggle_langs: Dilleri değiştir
create:
  media:
    project: Medya Projesi Oluştur
errors:
  business: |-
    Hatalar vardı. 
    Lütfen aşağıdaki hata ayrıntılarını kontrol edin.


    {errors}
general: {}
intro:
  hi: Merhaba, {name}!
not-found: Bulunamadı
receiver: alıcı
