import Keycloak from 'keycloak-js'
import { useEnvironmentSettings } from '~/env'

const environmentSettings = useEnvironmentSettings()

const initOptions = {
  url: environmentSettings.keycloak.baseUrl,
  realm: environmentSettings.keycloak.realm,
  clientId: environmentSettings.keycloak.clientId,
}
const keycloak = new Keycloak(initOptions)

export function useKeycloak() {
  return keycloak
}

