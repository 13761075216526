button:
  about: 关于
  back: 返回
  home: 首页
  toggle_dark: 切换深色模式
  toggle_langs: 切换语言
create:
  media:
    project: 创建媒体项目
errors:
  business: |-
    有错误。
    请检查下面的错误详细信息。


    {errors}
general: {}
intro:
  hi: 你好，{name}
not-found: 未找到页面
receiver: 接受者
