button:
  about: À propos
  back: Retour
  home: Accueil
  toggle_dark: Basculer en mode sombre
  toggle_langs: Changer de langue
create:
  media:
    project: Créer un projet multimédia
errors:
  business: |-
    Il y a eu des erreurs. 
    Veuillez vérifier les détails de l'erreur ci-dessous.


    {errors}
general: {}
intro:
  hi: Salut, {name}!
not-found: Page non trouvée
receiver: Destinataire
