<script setup lang="ts">
import { MediaProjectDraftControllerApi, MediaProjectType } from '~/api/gen'
import { getConfiguration, httpClient } from '~/axios'

const controller = new MediaProjectDraftControllerApi(getConfiguration(), undefined, httpClient)

const result = await controller.getLatestProjectDrafts()
const list = result.data.value!.mediaProjects

const { t } = useI18n()
</script>

<template>
  <div class="flex flex-wrap">
    <div v-if="list?.length <= 0">
      {{ t('project-overview.no-drafts') }}
    </div>
    <template v-else>
      <div v-for="item in list" :key="item.id">
        <Draft :data="{ id: item.id, name: item.name, type: MediaProjectType.AfterDeath, categoryName: item.videoCategoryName }" />
      </div>
    </template>
  </div>
</template>
