<script lang="ts" setup>
const props = defineProps<{
  action: () => Promise<void>
  categoryName: string
}>()

const state = reactive({
  svg: { backgroundImage: '' },
  inProgress: false,

})

// dynamic  import Logo from `~/svg/${props.categoryName}.svg?url`
import(`../../svg/${props.categoryName}.jpg`)
  // .then(e => state.svg = { backgroundImage: `url(${e.default})` })
  .then(e => state.svg = { backgroundImage: `url(${e.default})` })

const handleAction = async () => {
  try {
    state.inProgress = true
    await props.action()
    state.inProgress = false
  }
  catch (e: any) {
    state.inProgress = false
  }
}
</script>

<template>
  <button :style="state.svg" :disabled="state.inProgress" class="p-6 rounded-lg shadow-lg bg-white dark:text-font max-w-sm dark:bg-gray-700" @click="handleAction">
    <slot />
  </button>
</template>
