button:
  about: 소개
  back: 뒤로가기
  home: 홈
  toggle_dark: 다크모드 토글
  toggle_langs: 언어 변경
create:
  media:
    project: 미디어 프로젝트 만들기
errors:
  business: |-
    오류가 있었습니다. 
    아래 오류 내용을 확인하십시오.


    {errors}
general: {}
intro:
  hi: 안녕, {name}!
not-found: 찾을 수 없습니다
receiver: 받는 사람
