import type { Language } from './../modules/i18n'
import { useKeycloak } from '~/modules/keycloak'
import type { AuthorStatus } from '~/api/gen'

export class User {
  constructor(public language: Language, public id: string, public token?: string, public refreshToken?: string, public firstName?: string,
    public lastName?: string, public emailAddress?: string, public usedSpace?: number, public totalSpace?: number, public needsWillRegistration?: boolean, public authorStatus?: AuthorStatus,
    public isAfterDeathPackageActive?: boolean, public storageSeats?: number,
    public totalMediaProjectsAfterDeath?: number, public totalMediaProjectCount?: number, public dateOfBirth?: Date,
    public cookiesAcceptedAt?: Date) {
  }

  public isLoaded(): boolean {
    return !!this.totalSpace
  }

  public hasRole(role: string): boolean {
    return (useKeycloak().tokenParsed!.realm_access?.roles.indexOf(role) ?? -1) > 0
  }

  isAuthenticated(): boolean {
    return useKeycloak().authenticated ?? false
  }
}
