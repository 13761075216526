

import __layout_0 from '/C:/Users/root/Dev/knatec/imma/repo/webapp/src/layouts/default.vue'
export const layouts = {
'404': () => import('/C:/Users/root/Dev/knatec/imma/repo/webapp/src/layouts/404.vue'),
'default': __layout_0,
'fullwidth': () => import('/C:/Users/root/Dev/knatec/imma/repo/webapp/src/layouts/fullwidth.vue'),
'home': () => import('/C:/Users/root/Dev/knatec/imma/repo/webapp/src/layouts/home.vue'),
'public': () => import('/C:/Users/root/Dev/knatec/imma/repo/webapp/src/layouts/public.vue'),
'wide': () => import('/C:/Users/root/Dev/knatec/imma/repo/webapp/src/layouts/wide.vue'),
}

export function setupLayouts(routes) {
  return routes.map(route => {
    return { 
      path: route.path,
      component: layouts[route.meta?.layout || 'default'],
      children: [ {...route, path: ''} ],
    }
  })
}
