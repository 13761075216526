button:
  about: Sobre
  back: Voltar
  home: Início
  toggle_dark: Alternar modo escuro
  toggle_langs: Mudar de idioma
create:
  media:
    project: Criar projeto de mídia
errors:
  business: |-
    Houve erros. 
    Verifique os detalhes do erro abaixo.


    {errors}
general: {}
intro:
  hi: Olá, {name}!
not-found: Não encontrado
receiver: Destinatário
