import type { PluginOptions } from 'vue-toastification'
import Toast from 'vue-toastification'
import * as pkg from 'vue-toastification'
import { type UserModule } from '~/types'
import 'vue-toastification/dist/index.css'

const { POSITION } = pkg

export const install: UserModule = ({ app }) => {
  const options: PluginOptions = {
    position: POSITION.BOTTOM_RIGHT,
    timeout: 5000,
    closeOnClick: false,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: false,
    closeButton: 'button',
    icon: true,
    rtl: false,
  }

  app.use(Toast, options)
}
