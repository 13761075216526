Enter Time: Enter Time
PassedAway: Passed
WillRegistered: Consent registered
accept-stripe-cookies: >-
  We will redirect you to our payment page, which is provided by Stripe. This
  site stores cookies, which are used for fraud prevention, among other things.
  Before you can upload your video, we must obtain your permission to do
  so.<br/> 

  You can find more information about cookies at <a
  href="https://stripe.com/de/legal/cookies-policy" class="link"
  target="stripe_cookies">Cookie Policy</a>.
address: Address
assets: Assets
assets-information:
  abos:
    hint-text: >-
      Subscriptions are still active until they are cancelled. Tell your
      recipients about them so they can cancel them as soon as possible and save
      money. E.g. Netflix, RTL Plus, accident insurance with Allianz/Uniqa/Wr.
      Städtische, occupational disability insurance with ..., ORF household tax,
      ... <br/> Also check out <a target="abo_list" class="link"
      href="https://www.oesterreich.gv.at/themen/gesundheit_und_notfaelle/todesfall/Seite.190900.html">this
      list</a>.
    label: 'Subscriptions and the like, which you should cancel as soon as possible:'
  assetsHardToFind: 'Assets hard to find:'
  credentials:
    hint-text: >-
      Amazon username/email + password; Password for mobile network operators
      (usually 4 digits), ...
    label: 'Credentials to online providers:'
  heading: Information about assets and subscriptions (optional)
  hiddenAssets:
    hint-text: >-
      The hiding places of money, gold, Bitcoin hardware wallets, access data
      for online banking and online wallets, ...
    placeholder: >-
      I keep the access data to my safe on my bookshelf between Harry Potter -
      Philosopher's Stone and Harry Potter - The Prisoner of Azkaban.
  info: >-
    Let your recipients know your current statement of assets and how they can
    access it. Save your heirs money by informing them about current contracts
    and subscriptions that they should cancel as soon as possible in the event
    of your death.
  whereToGetMoney:
    hint-text: Uniqa life insurance (policy no. ...), Wiener Verein death insurance, ...
    label: 'My heir still gets money there:'
    placeholder: >-
      {'Contact Uniqa at heinz.leitner@uniqa.at with my policy nr. 22334983839
      and inform him about my death.'}
author-management:
  title: Author Status
authorStatus:
  registration-done-info: The registration was successfully processed! &#x1F973;
  signature-verified: The signature was successfully marked as verified.
button:
  about: About
  back: Back
  home: Home
  toggle_dark: Toggle dark mode
  toggle_langs: Change languages
city: City
components:
  select-one: Please select one
country: Country - Delivery Time
create:
  media:
    project: Create Media Project
customer-management:
  passed-away: Passed Away
  title: Customer Management
  will-registered: Testament Registered
date-of-birth-saved: Your date of birth was successfully saved.
e-mail-address: E-Mail Address
enter-time:
  info: >-
    Choose the date you want the recipient to receive your message. Enter the
    desired date in your timezone and select the timezone of the recipient. Our
    system considers the recipient's timezone to send the message.
  when-should-video-be-delivered: When should the video arrive at the recipient?
errors:
  business: |-
    There were errors. Please check the error details below.

    {errors}
  dateInvalid: The date is not complete or does not exist.
  fileTooLargeOrNotPdf: The file must be of type .pdf and have a size less than 10MB.
  form-validation-failed: Sorry, there were validation errors. Please check your inputs.
  maxDate: The date must not be more than one year in the future.
  minDate: The date must be in the future.
  minDateLetter: >-
    The date must be at least {days} days in the future to prepare the letter
    and guarantee delivery at the desired date.
  oops: >-
    We are sorry - something went wrong! We received the error report and are
    working on it already to fix the problem! You could try to refresh the page
    in the meantime.
  save-date-of-birth: >-
    Unfortunately, an error occurred while saving the date of birth. Please try
    again or contact our <a href="mailto:support&#x0040;imma.video">support
    team</a>. 
  unauthorized: >-
    Your login has expired. Please refresh the page or go to the login page and
    login again.
  unsupportedMimeType: The video is of type '{0}'. Please try another browser or contact support.
general:
  from-beyond: From Afterlife
  joyful-occasion: Joyful Occasion
  start-project: Create video message
global:
  add: Add
  address: Address
  back-to-project-overview: Back to Project Overview
  cancel: Cancel
  continue: Continue
  convert: Convert
  create: Create
  dark-mode: Dark Mode
  delete: Delete
  delete-now: Delete now
  drafts: Started Video Messages
  edit-assets: Edit Assets & Subscriptions
  edit-recipient: Edit Recipient
  edit-time: Edit Time
  edit-video: Watch/Edit Video
  filename: Filename
  first-name: First Name
  go-to-home: Go to homepage
  home: Home
  imprint: Imprint
  language: Language
  last-name: Last Name
  logout: Logout
  media-project-id: Video Message Id
  next: Next
  pay-now: Pay now
  preferred-contact-times: Preferred contact times
  privacy-policy: Privacy Policy
  project-name: Project Title
  project-name-hint-text: >-
    Name your video message to keep the overview.<br/>This title will only be
    shown to you.
  projects: Video Messages
  recently-started: Continue
  remove: Remove
  rename: Rename
  save: Save
  search: Search
  settings: Settings
  sign-now: Sign now
  status: Status
  storage: Storage
  terms-of-service: Terms of Service
  upload: Upload
  upload-and-publish: Upload
  used: used
  video-deleted: The video message was successfully deleted.
  watch-video: Watch Video
  your-first-name: Your first name
go-back: Go back
help:
  callback-error: >-
    The callback request was not successful. Please resend the callback request
    by clicking the button again.
  callback-requested: >-
    The callback request was successful. We will contact you as soon as
    possible.
  desired-callback-times-hint: Let us know when you would prefer to be contacted.
  request-callback: Request callback
intro:
  from-beyond-info: >-
    Leave a unique video message for your loved ones in the sad event of your
    death.  Thanks to the automated processes in the background, imma knows
    about your death and automatically sends the video messages on your behalf
    by e-mail or letter and ensures that they reach the recipient. So nobody has
    to take action to retrieve your video messages. 
  hi: Hi, {name}!
  joyful-occasion-info: >-
    Give something different for once! Something personal. Something for
    eternity. Something your loved ones will remember for a long time to come!

    imma offers the opportunity to record a video message and send it for a
    birth, wedding, Christmas or any other happy occasion. Record your video
    message now and enter the desired date on which the recipient should receive
    the message.
language: Language
media-library:
  add-now: Add now
  add-to-media-library: Add to media library
  confirm-and-go-to-pin: Bestätigen und weiter zur PIN-Eingabe
  go-to-media-library: Go to media library
  no-entries: You currently do not have any video messages in your media library.
  successfully-added: The video message was successfully added to your media library.
  successfully-removed: The video message was successfully removed from your media library.
media-project:
  add-to-media-library: Add to media library now
  clear-pin: Clear PIN
  enter-your-pin-code: Enter your pin code
  invalid-pin-or-not-found: The video was not found or the PIN you entered is not valid.
  public-watch-first-view-info: You successfully entered the PIN and got access to {name}'s message.
  public-watch-joyful-occasion-deleted-info: >-
    This video message has already been deleted by {name}. So that you don't
    lose access, you can still add it to your personal media library until
    {accessUntil}. If you have not added the message within this period, we will
    irrevocably delete it from our servers.
  public-watch-joyful-occasion-info: >-
    If {name} deletes the video message, we will inform you of the deletion by
    e-mail. You will continue to have access to the message for one month from
    this point and you can add it to your personal media library. If you have
    not added the video within this period, we will irrevocably delete it from
    our servers.
  public-watch-media-library-info: |-
    <p class="paragraph">
      The video message is available to you for 1 year from the first access. The content is therefore available <b>until {accessUntil}</b>.
    </p> <p class="paragraph">
      If you want <b>unlimited</b> access to {name}'s message, add it to your personal media library.
    </p>
  status:
    active: Active
    complete-draft: Complete Draft
    consentRegistrationRequired: Consent registration outstanding
    converting: Converting
    is-draft: Draft
    not-payed: Not payed
    payment-failed: Payment failed
    payment-in-progress: Payment started
    sent: Sent
    signature-verification: Signature verification
    space-required: Space Required
must-be-older-than-18: You must be older than 18 years to use this service.
my-projects: {}
navbar:
  draft-overview: Started Video Messages
  help: Help
  media-library: Media Library
  project-overview: Video Messages
next-steps:
  after-death-first-video:
    step1: >-
      Sign the consent document digitally with ID Austria (previously
      Handysignatur) and upload it.
    step2: >-
      After verifying your signature we will optimize your video message for
      different bandwidths and devices in the background to provide the best
      user experience possible. You can change the video message at any time.
  heading: Next steps
  joyful-occasion:
    nothing-to-do-for-you-info: >-
      Nothing to do for you! &#x1F60E; Just wait for the video message to make
      someone really happy! &#x1F60D;
  optimize-video-info: >-
    The video is optimized for different bandwidths and devices in the
    background to provide the best user experience possible for the recipient.
  thank-you: Thank you for uploading your video!
  will-already-registered: >-
    Nothing to do for you! All processes for the automatic video dispatch are
    already set up! This video message is going to be immediately active after
    successful payment.
not-found: This page could not be found. Please go back or navigate to the home page.
payment:
  already-done: >-
    The payment has already been made successfully. Go back to the project
    overview to check the status.
  cancelled-info: >-
    Your payment was cancelled. Please start the payment process again by
    clicking on the "Pay now" button.
  error: Your payment was not successful. Please try again.
  in-progress: >-
    Your payment is currently in progress. You will be informed about the
    payment status when it is processed.
  pay-now: Pay now
  requires-action: >-
    You did not finish the payment. Maybe you canceled the checkout or something
    else went wrong. Please retry.
  requires-payment-method: Your payment was not successful, please try again.
  status: {}
phone: Phone Number
pricing:
  first-after-death-video-info: >-
    For the initial setup we will charge you with 95€. This fee needs to be
    payed only once for setting up the automatic processes. Afterwards we will
    only charge you for the amount of storage used (5€ / 5GB und Jahr).
  free-tier-active: Free Tier is active.
  from: from
  storage-info: >-
    For the first video up to 200MB we will not charge you for any storage.
    Additional storage will be added in 5GB steps and each will be charged with
    5€ per year.
  storage-seats-used: 'Used storage seats in the current subscription period:'
project-overview:
  assets-updated: Information to assets and subscriptions successfully saved.
  no-drafts: You haven't started any video messages at the moment.
  no-projects: You currently do not have any video messages.
  recipient-updated: Recipient successfully updated.
  rename-successful: Video message successfully renamed.
  time-updated: Time successfully updated.
  video-uploaded: Video successfully updated.
receiver: Recipient
recipient:
  delivery-country-text: '{name} - 1 day | {name} - {n} days'
  hint-language: >-
    Select the language of the recipient. All communication to the recipient
    will consider this language selected here.
  hint-language-offline-sale: >-
    The language of the card is preselected and fixed. All communication to the
    recipient will consider the language selected here.
  hint-phone-number: >-
    A phone number helps us to get in touch with the recipient if some problems
    with the delivery occur.
  make-video-more-personal: Make your message even more personal!
  make-video-more-personal-info: >-
    Let us send your video via a personalized letter. We will print a QR- and
    PIN-code on it to access the video message. We take care that the letter
    will reach the recipient at the desired date.
  message-will-be-send-via-letter: We will send your video message via letter to the recipient data below.
  open-preview: Open preview
  send-via-letter: Send via letter
save-and-continue-later: Save and continue later
scheduled-send: Scheduled Send
select-a-type-first: >-
  Please select if you want to use it as a video message from afterlife or for a
  joyful occasion.
sent-video:
  deletion-error: >-
    An error occurred when trying to delete the video. Please try again by
    clicking the "Delete now" button again. If that did not help, please <a
    href="/help#contact" class="link">contact us</a>.
  deletion-heading: Delete sent video
  deletion-info: >-
    <p class="paragraph"> If the recipient did not add your video message to her
    personal media library, we will send an email about the deletion to the
    recipient. She has <b>one month left</b> to add your video message to her
    media library until the video is deleted from our servers.  </p> <p
    class="paragraph"> Free Space will immediately increase in your account
    after hitting the "Delete now" button and you <b>won't have access</b> to
    the video afterwards! </p>
server-validation:
  dateMustBeInTheFuture: The date must be in the future.
settings:
  language-saved: Language saved.
sign-consent:
  download-signed-constent: Download consent document
  heading: Download, sign and upload consent document
  info: >-
    We need your digitally signed consent to activate your video messages from
    beyond. This is a one-time setup process and further videos will be directly
    active. <br/> Video messages for joyful occasions do not need this consent
    and are always active after upload. 

    <br/><br/> Please download the consent document and use a signing platform
    like <a class="link" href="https://www.prime-sign.com/primesign/"
    target="primesign">PrimeSign</a> to sign the document. <br/><br/> If you
    need help, please <a class="link" href="/help#contact">contact us</a>.
  info-upload: Then upload the digitally signed consent here.
  verifying: >-
    We have received your consent document and are now checking the signature.
    We will send you a confirmation email when we have successfully verified the
    signature and your video messages will become active.
successful-saved: Data saved.
time: Time
timezone: Timezone
upload:
  change-video: Change video
  conversion-error: >-
    We are very sorry! The video conversion unfortunately failed. We already
    received the error report and are investigating to solve this issue. In the
    meantime you can retry uploading with another browser. Feel free to contact
    our support if it still does not work.
  conversionInfo: >-
    The video will be converted to the chosen video category design. After
    conversion you can review the video, no payment required for this step.
  conversionInfoOfflineSale: >-
    The video will be converted to the video category design of the card. After
    conversion you can review and/or edit the video.
  freetier-info:
    heading: Free Tier Storage will be active
    message: >-
      Congratulations! This video is included in the free tier storage and you
      won't be charged for storage costs!
  freetier-storage-exceeds-info:
    heading: Free Tier Storage will exceed
    message: >-
      This video is not included within the free tier storage as it is larger
      than 200MB. You will be charged with 5€ / year for 5GB. Removing video
      messages will downgrade you automatically in the next subscription period
      if less storage is used.
  heading: Watch video!
  info: >-
    You can record a video with your camera or upload a video from your device.
    The video can be changed at any time unless the message is sent.
  info-already-sent: >-
    Your video has already been sent. Therefore, you can no longer change it,
    but you can watch it.
  leaving-freetier:
    heading: Leaving Free Tier Storage
  leaving-freetier-info:
    message: >-
      You will leave the free tier after publishing this video and be charged
      with 5€ / year for 5GB. Removing video messages will downgrade you 
      automatically in the next subscription period if less storage is used.'
  patience-hint: >-
    Please be patient and prevent refreshing the browser! Depending on the size
    of the video, the conversion can take up to some minutes.
  previewErrorInfo: >-
    <p>At the moment it is not possible to preview your video because your
    browser does not support this format.</p> <p><b>Don't worry!</b></p>
    <p>After uploading, we convert your video into a stream that is supported in
    all browsers and devices,  so that you and the recipients have the best
    possible user experience.</p>
  select-another: Select another video
  storage-exceeds-info:
    heading: Storage Limits will exceed
    message: >-
      You will be upgraded to the next 5GB of storage within this subscription
      period after publishing this video. If you don't want that, delete other
      video messages before uploading or upload a smaller or shorter video
      message. You will only be charged for the maximum used storage within a
      subscription year. Removing video messages will downgrade you
      automatically in the next subscription period if less storage is used.
  upload-new-video: Upload new video
upload-your-video: Upload your video!
validations:
  accept-cookies: You must accept the cookies in order to continue.
  checked: You must confirm the checkbox.
  email: This is not a valid email address.
  maxDate: The date must be before {maxDate}.
  minDate: The date must be after {minDate}.
  minLength: The field must have a min length of {min}.
  phone: >-
    The phone number must be numeric and may not contain blanks. It may start
    with a '+'.
  required: The field is required.
  video-must-be-uploaded: Please upload or record your video first.
video:
  loading-error: >-
    A problem occurred while loading the video. Please try to reload the page
    (F5)! If that did not help, please reach out to contact us.
video-categories:
  BirthBoy: Birth Boy
  BirthGirl: Birth Girl
  Birthday: Birthday
  Christmas: Christmas
  Easter: Easter
  FathersDay: Father's Day
  MothersDay: Mother's Day
  NewYear: New Year
  ThankYou: Thank you
  ValentinesDay: Valentine's Day
  Wedding: Wedding
  select-info: Choose one of the occasions and start creating your video message.
who-should-receive-the-video: Who should receive your video?
zip: Zip
