button:
  about: Acerca de
  back: Atrás
  home: Inicio
  toggle_dark: Alternar modo oscuro
  toggle_langs: Cambiar idiomas
create:
  media:
    project: Crear proyecto multimedia
errors:
  business: |-
    Hubo errores. 
    Por favor, compruebe los detalles del error a continuación.


    {errors}
general: {}
intro:
  hi: ¡Hola, {name}!
not-found: No se ha encontrado
receiver: Recipiente
