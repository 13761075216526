import * as validators from '@vuelidate/validators'
import { createI18nMessage, helpers } from '@vuelidate/validators'
import dayjs from 'dayjs'
import { i18n } from './i18n'

const withI18nMessage = createI18nMessage({ t: i18n.global.t.bind(i18n) })
export const required = withI18nMessage(validators.required)
export const numeric = withI18nMessage(validators.numeric)
export const email = withI18nMessage(validators.email)
export const checked = withI18nMessage((value: unknown) => value === true)
export const minLength = withI18nMessage(validators.minLength, { withArguments: true })
export const requiredIf = withI18nMessage(validators.requiredIf, { withArguments: true })

const phoneValidation = (value: string) => {
  return (value?.length ?? 0) === 0 || /^[+\d\(][\d \-()/.]*$/.test(value)
}

export const phone = withI18nMessage(phoneValidation)

export const minDate = (min: Date | number) => helpers.withMessage(i18n.global.t('validations.minDate', { minDate: dayjs(min).format(dayjs().localeData().longDateFormat('L')) }), helpers.withParams(
  { type: 'minDate', value: min },
  (value: string) => {
    const date = new Date(value)
    const min_ = new Date(min)
    const minWithoutTime = new Date(min_.getFullYear(), min_.getMonth(), min_.getDate())

    return date >= minWithoutTime
  }))

export const maxDate = (max: Date | number) => helpers.withMessage(i18n.global.t('validations.maxDate', { maxDate: dayjs(max).format(dayjs().localeData().longDateFormat('L')) }), helpers.withParams(
  { type: 'maxDate', value: max },
  (value: string) => {
    const date = new Date(value)
    const max_ = new Date(max)
    const maxWithoutTime = new Date(max_.getFullYear(), max_.getMonth(), max_.getDate())

    return date < maxWithoutTime
  }))

