import '@unocss/reset/tailwind.css'
import 'dayjs/locale/de'
import 'dayjs/locale/en'
import 'uno.css'
import { setupLayouts } from 'virtual:generated-layouts'
import { ViteSSG } from 'vite-ssg'
import * as Sentry from '@sentry/vue'
import App from './App.vue'
import { getCurrentLanguage } from './modules/i18n'
import { useKeycloak } from './modules/keycloak'
import { User } from './services/user'
import './styles/main.css'
import type { UserModule } from './types'
import generatedRoutes from '~pages'
import '/node_modules/flag-icons/css/flag-icons.min.css'

const routes = setupLayouts(generatedRoutes)

const scrollBehavior = (to: any, from: any, savedPosition: any) => {
  if (savedPosition)
    return savedPosition
  else
    return { top: 0 }
}

// https://github.com/antfu/vite-ssg
export const createApp = ViteSSG(
  App,
  { routes, scrollBehavior, base: import.meta.env.BASE_URL },
  async (ctx) => {
    Object.values(import.meta.glob<{ install: UserModule }>('./modules/*.ts', { eager: true }))
      .forEach(i => i.install?.(ctx))

    Sentry.init({
      app: ctx.app,
      environment: import.meta.env.MODE,
      tracePropagationTargets: ['localhost', /^https:\/\/api-dev.imma\.video\/api/],
      dsn: 'https://63d5c5bfa3967a0a030fd7ac84de7144@o4506305312456704.ingest.sentry.io/4506310668255232',
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(ctx.router),
        }),
        new Sentry.Replay(),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, // Capture 100% of the transactions
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })
    // BRAUCH MA DAS? Ja!

    if (!window.location.pathname.startsWith('/p/')) {
      // This is initial browser load / refresh.
      // Keycloak is not initialized on public URLs otherwise Keycloak would add ugly url parameters to the public url like error=login_required
      const keycloak = useKeycloak()
      const authenticated = await keycloak!.init({ checkLoginIframe: false, redirectUri: window.location.href, messageReceiveTimeout: 3000, onLoad: 'check-sso' })

      if (authenticated) {
        const store = useUserStore()
        const profile = await keycloak.loadUserProfile()

        await store.setLoggedInUser(new User(
          getCurrentLanguage(),
          profile.id!,
          keycloak.token,
          keycloak.refreshToken,
          profile.firstName,
          profile.lastName,
          profile.email,
        ))
        await store.updateAuthorData()
      }
    }

    ctx.router.beforeEach((to, from, next) => {
      if (to.meta.isPublic) {
        next()
      }
      else {
        const userStore = useUserStore()
        const needsRole = !!to.meta.role

        if (!userStore.user.isAuthenticated()) {
          // If Keycloak is not yet initialized we do it here if navigated from a Public Url to a secured one.
          const redirectUri = `${window.location.protocol}//${window.location.host}/${to.path}${window.location.search}`
          const keycloak = useKeycloak()
          keycloak!.init({ checkLoginIframe: false, messageReceiveTimeout: 3000, onLoad: 'login-required', redirectUri })
            .then(async (authenticated) => {
              if (authenticated) {
                const store = useUserStore()
                const profile = await keycloak.loadUserProfile()

                store.setLoggedInUser(new User(getCurrentLanguage(),
                  profile.id!,
                  keycloak.token,
                  keycloak.refreshToken,
                  profile.firstName,
                  profile.lastName,
                  profile.email,
                ))
                await store.updateAuthorData()
                next()
              }
            })
        }
        else if (needsRole) {
          const hasRole = userStore.user.hasRole(to.meta.role as string)

          if (hasRole)
            next()
          else
            ctx.router.push('not-found')
        }
        else {
          next()
        }
      }
    })
  },
)

