<script setup lang="ts">
import { storeToRefs } from 'pinia'

const userStore = useUserStore()
const { user } = storeToRefs(userStore)
</script>

<template>
  <Navbar />
  <main class="px-2 md:px-4 py-10 mx-auto">
    <LoadingSpinner
      :loading="!user?.isLoaded()"
    />

    <RouterView v-if="user?.isLoaded()" />
  </main>
</template>
