interface EnvironmentSettings {
  keycloak: {
    baseUrl: string
    realm: string
    clientId: string
  }
}

export function useEnvironmentSettings(): EnvironmentSettings {
  return {
    keycloak: {
      baseUrl: import.meta.env.VITE_KEYCLOAK_URL as string,
      realm: import.meta.env.VITE_KEYCLOAK_REALM as string,
      clientId: import.meta.env.VITE_KEYCLOAK_CLIENTID as string,
    },
  }
}
