button:
  about: これは？
  back: 戻る
  home: ホーム
  toggle_dark: ダークモード切り替え
  toggle_langs: 言語切り替え
create:
  media:
    project: メディアプロジェクトの作成
errors:
  business: |-
    エラーがありました。
    以下のエラーの詳細を確認してください。


    {errors}
general: {}
intro:
  hi: こんにちは、{name}!
not-found: 見つかりませんでした
receiver: 受信者
