/* tslint:disable */
/* eslint-disable */
/**
 * imma API
 * imma is a platform to manage emotional messages for your beloved ones and send those on trigger events
 *
 * The version of the OpenAPI document: v0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const MediaProjectType = {
    AfterDeath: 'AfterDeath',
    JoyfulOccasion: 'JoyfulOccasion'
} as const;

export type MediaProjectType = typeof MediaProjectType[keyof typeof MediaProjectType];



